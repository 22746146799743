import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Img from "gatsby-image";


export const AboutPageTemplate = ({ title, image0, image1, image2, content, secondContent, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <div
        className="header-buffer"
        style={{
          height: "108px",
          background: "#0b2224",
          marginBottom: "54px",
        }}
      ></div>
      <section className="section section--gradient" style={{paddingTop: "0px"}}>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h1>
                <Img fluid={image0.childImageSharp.fluid} style={{marginBottom: "30px"}} alt="Tim Cannon Master Craftsman and Cindi Cannon Operations Manager of Cannon Custom Woodworking" />
                <PageContent className="content" content={content}/>
                <br style={{clear: 'both'}}></br>
                <Img fluid={image2.childImageSharp.fluid} style={{marginBottom: '40px', marginTop: '0px'}} alt="Handmade wood table and custom dining room furniture"/>
                <p>{secondContent}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  secondContent: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout canonical="https://www.cannoncustomwoodworking.com/about/" description="About Cannon Custom Woodworking in Bethel, OH. Learn more about Tim and Cindi Cannon on our About Us page." title="About Cannon Custom Woodworking in Bethel, OH">
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image0={post.frontmatter.image0}
        image1={post.frontmatter.image1}
        image2={post.frontmatter.image2}
        content={post.html}
        secondContent={post.frontmatter.secondContent}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image0 {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 800, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1070, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        secondContent
      }
    }
  }
`;
